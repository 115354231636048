/** @jsx jsx */
import { useContext, useState } from "react"
import { jsx, useThemeUI } from "theme-ui"
import Image from "./image"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Link from "./link"
import { ArrowIcon } from "../assets/icons"
import { TransformLinkUri } from "./link"
import Markdown from "react-markdown"
import CloseIcon from "../assets/close-icon"
import { ab } from "../theme"

const PageTeaser = ({
  image,
  imageAlt,
  children,
  heading,
  subHeading,
  pageLink,
  linkText,
  linkTarget,
  mode,
  download,
  currentLanguage,
  linkedImage,
  popupText,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme
  const [popupTextOpened, setPopupTextOpened] = useState(false)

  const modeKey = mode ? mode : globalMode

  let imageElement = (
    <Image
      image={
        typeof image === "object"
          ? {
              ...image,
              sizes: "(min-width: 824px) 432px, 100vw",
            }
          : image
      }
      sizes={["100vw", "100vw", "34vw", "34vw"]}
      alt={imageAlt}
    />
  )

  let arrowElement
  if (popupText) {
    arrowElement = (
      <div
        sx={{
          position: "relative",
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pr: "20px",
            pt: "20px",
            variant: "pageTeaser.arrow",
          }}
        >
          <div
            role="button"
            onClick={() => setPopupTextOpened(true)}
            sx={{
              width: "38px",
              textAlign: "right",
              cursor: "pointer",
              right: 0,
              svg: {
                fill: "#fff",
                transform: "rotateY(180deg)",
              },
            }}
          >
            <ArrowIcon />
          </div>
        </div>

        <div
          className="popup"
          sx={{
            variant: "pageTeaser.popup",
            display: popupTextOpened ? "block" : "none",
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,0.9)",
            zIndex: 102,
          }}
        >
          <div
            className="popup-text"
            sx={{
              position: "fixed",
              top: "50%",
              left: ab({ _: "0%", md: "50%" }),
              transform: ab({
                _: " translate(0%, -50%)",
                md: "translate(-50%, -50%)",
              }),
              maxWidth: "100%",
              p: "30px",
              m: ab({ _: "auto 020px", md: "auto" }),
              fontSize: "18px",
              h2: {
                font: "24px",
              },
            }}
          >
            <div
              role="button"
              onClick={() => setPopupTextOpened(false)}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                width: "38px",
                height: "38px",
                padding: "2px",
                cursor: "pointer",
              }}
            >
              <CloseIcon style={{ fill: "#fff" }} />
            </div>
            <Markdown
              urlTransform={(uri) => {
                return TransformLinkUri(uri)
              }}
            >
              {popupText}
            </Markdown>
          </div>
        </div>
      </div>
    )
  }

  if (linkedImage) {
    imageElement = (
      <Link
        to={pageLink}
        download={download}
        target={linkTarget}
        language={currentLanguage}
      >
        {imageElement}
      </Link>
    )
  }

  return (
    <div
      className="page-teaser"
      data-mode={mode}
      sx={{
        bg: modeKey
          ? modeKey === "none"
            ? colors.modes[modeKey].background
            : colors.modes[modeKey].primary
          : colors.modes["group"].primary,
        my: 3,
        display: "flex",
        flexDirection: "column",
        variant: "guc.pageTeaser",
      }}
    >
      {imageElement}
      <div
        sx={{
          variant: "pageTeaserText",
          textAlign: "center",
          color: modeKey === "none" ? colors.modes[modeKey].text : "background",
          fontSize: 1,
          p: 3,
          position: "relative",
          flex: 1,
          "& p": {
            marginBottom: 0,
          },
          pb: linkText ? "71px" : 3,
        }}
        className="text"
      >
        <h2
          sx={{
            py: 0,
            m: 0,
            fontWeight: 1,
            hyphens: "auto",
          }}
        >
          {heading}
        </h2>
        {subHeading && (
          <h3
            sx={{
              variant: "pageTeaser.h3",
            }}
          >
            {subHeading}
          </h3>
        )}
        <div
          sx={{
            variant: "pageTeaser.child",
          }}
        >
          {children}
        </div>

        {arrowElement}

        <div
          sx={{
            width: "calc(100% - 32px)",
            display: linkText ? "block" : "none",
            position: "absolute",
            bottom: 3,
          }}
        >
          <Link
            to={pageLink}
            download={download}
            target={linkTarget}
            language={currentLanguage}
            sx={{
              display: "inline-block",
              variant: "styles.ghostButton",
              borderColor:
                modeKey === "none" ? colors.modes[modeKey].text : "background",
              color:
                modeKey === "none" ? colors.modes[modeKey].text : "background",
              py: "7px",
              px: "40px",
              marginTop: 3,
              "&:hover": {
                bg:
                  modeKey === "none"
                    ? colors.modes[modeKey].text
                    : "background",
                color: modeKey
                  ? modeKey === "none"
                    ? colors.modes[modeKey].background
                    : colors.modes[modeKey].primary
                  : colors.modes["group"].primary,
              },
            }}
          >
            {linkText}
          </Link>
        </div>
      </div>
    </div>
  )
}

PageTeaser.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  linkedImage: PropTypes.bool,
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  pageLink: PropTypes.string,
  linkText: PropTypes.string,
  download: PropTypes.bool,
  linkTarget: PropTypes.string,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
}

PageTeaser.defaultProps = {
  mode: "",
  download: false,
  linkedImage: false,
}

export default PageTeaser
